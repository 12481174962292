import React, { useState } from 'react';
import {connect} from "react-redux";
import ButtonBase from '@mui/material/ButtonBase';
import {PopupTitle} from '../../title/title';
import { MuiChipsInput } from 'mui-chips-input'
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';
import copy from 'copy-to-clipboard';


// styles 
import './shareCollection.scss';

// icon
import link from '../../../img/ico/linkIcon.svg';
import { openNotification } from '../../../actions/notification';
import { changeCollectionSharedStatus, appURL } from '../../../service/requests';

const mapDispatchToProps = (dispatch) => ({
   openNotification: (payload) => {
     return dispatch(openNotification(payload));
   },
 });

const ShareCollection = (props) => {
   
   const {openNotification, info} = props;
   const [shareType, setShareType] = useState("link");
   const [errorMessage, setErrorMessage] = useState("");
   const [selectedEmails, setSelectedEmails] = useState([]);
   const [isShared, setIsShared] = useState(info.collection.shared);
   const [isLoading, setIsLoading] = useState(false);

   const onCopyLink = () => {
      // navigator.clipboard.writeText(`http://localhost:3000/collection/${info.collection.id}`);
      copy(`${window.location.origin}/collection/${info.collection.id}`);
      openNotification({
         text: "Copied to clipboard",
         notificationType: "success",
      })
   }

   const toggleSharedState = async (shared) => {
      setIsLoading(true)
      try {
         await changeCollectionSharedStatus(info.collection.id, {shared: shared});
         setIsShared(shared);
      } catch(e) {
         console.log(e)
         openNotification({
            text: "Something went wrong",
            notificationType: "error",
         })
      }
      setIsLoading(false)
   }

   const AccessToAll = () => {
      return (
         <div className="mx-auto w-min mt-10 rounded-lg overflow-hidden">
            <ButtonBase><div className="bg-darkPurple py-1.5 px-6 text-white text-base rounded-lg w-max hover:bg-hoverPurple transition cursor-pointer">Share</div></ButtonBase>
         </div>
      ) 
   }


   const SelectedUsers = () => {

      const validateEmail = (email) => {

         if (email !== "undefined" && email !== '') {
            let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            let isExist = selectedEmails.includes(email);

            if (!pattern.test(email)) {
               setErrorMessage("Please enter valid email address.")
               return false
            }

            if (isExist) {
               setErrorMessage("This email already exist.")
               return false
            }

            return true
         }

      }

      const onAddEmail = (email) => {
         let isValid = validateEmail(email);
         if(isValid) {
            setSelectedEmails([...selectedEmails, email])
            setErrorMessage("")
         }
      }

      const handleDeleteChip = (email) => {
         setSelectedEmails([...selectedEmails.filter(x => x !== email)])
      }

      return (
         <div className="mt-5">
            <div className="text-sm font-light text-textGray dark:text-darkModeGray">Invite guests</div>
            <MuiChipsInput size="small" className="chipInput w-full" value={selectedEmails} disableClearable onAddChip={onAddEmail} onDeleteChip={handleDeleteChip} />
            <div className="text-errorRed text-center mt-1 text-xs">{errorMessage}</div>
            <div className="flex justify-between items-center mt-6">
               <div className="text-sm font-light text-textGray dark:text-whitish">Invite<span className="py-1 px-2 bg-darkOrange mx-1 text-white font-normal rounded-md">{selectedEmails.length} guests</span> to this collection</div>
               <ButtonBase><div className="px-7 py-1.5 bg-darkPurple text-white rounded-md cursor-pointer transition hover:bg-hoverPurple">Invite</div></ButtonBase>
            </div>
         </div >
      )
   }

   const AccessByLink = () => {
      return (
         <div className="relative">
            {isLoading && <CircularProgress size={25} className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />}
            <div className={isLoading && "opacity-40 pointer-events-none"}>
               <div className='mt-4'>
                  <FormControlLabel
                     style={{ marginLeft: 0 }}
                     control={<IOSSwitch sx={{ m: 1 }} checked={isShared}
                        onChange={() => toggleSharedState(!isShared)}
                        defaultChecked />}
                     label={<div style={{ fontFamily: 'Poppins' }} className='text-sm text-dark dark:text-whitish'>Shared collection</div>}
                     labelPlacement="start"
                  />
               </div>
               <div className={`flex justify-between items-center mt-4 mb-7 transition ${isShared ? 'opacity-1' : 'opacity-40 pointer-events-none'}`}>
                  <div className="flex items-center"><img src={link} /><span className="text-sm w-48 ml-3 leading-4 text-dark dark:text-whitish">Everyone who has a link can view</span></div>
                  <ButtonBase className="copyLink" onClick={onCopyLink}>
                     <div className="text-purple cursor-pointer transition hover:text-hoverPurple py-1 px-2">Copy Link</div>
                  </ButtonBase>
               </div>
            </div>
         </div>
      )
   }

   const loadComponent = () => {
      switch (shareType) {
         case 'all':
            return <AccessToAll />
         case 'selectedUsers':
            return <SelectedUsers />
         case 'link':
            return <AccessByLink />
         default:
            return <AccessToAll />
      }
   }



   return (
      <section className="my-6 mx-5 md:mx-20">
         <PopupTitle title="Share collection"/>
         <div className="flex justify-center mt-5 gap-5">
            {/* <div className={shareType === "all" ? "shareType--active" : "shareType"} onClick={() => setShareType("all")}>Access to all</div> */}
            <div className={shareType === "selectedUsers" ? "shareType--active" : "shareType"} onClick={() => setShareType("selectedUsers")}>To selected users</div>
            <div className={shareType === "link" ? "shareType--active" : "shareType"} onClick={() => setShareType("link")}>Access by link</div>
         </div>
         {loadComponent()}

      </section>
   )
}

export default connect(null, mapDispatchToProps)(ShareCollection);


const IOSSwitch = styled((props) => (
   <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
 ))(({ theme }) => ({
   width: 42,
   height: 26,
   padding: 0,
   '& .MuiSwitch-switchBase': {
     padding: 0,
     margin: 2,
     transitionDuration: '300ms',
     '&.Mui-checked': {
       transform: 'translateX(16px)',
       color: '#fff',
       '& + .MuiSwitch-track': {
         backgroundColor: '#6A75CA',
         opacity: 1,
         border: 0,
       },
       '&.Mui-disabled + .MuiSwitch-track': {
         opacity: 0.5,
       },
     },
     '&.Mui-focusVisible .MuiSwitch-thumb': {
       color: '#33cf4d',
       border: '6px solid #fff',
     },
     '&.Mui-disabled .MuiSwitch-thumb': {
       color:
         theme.palette.mode === 'light'
           ? theme.palette.grey[100]
           : theme.palette.grey[600],
     },
     '&.Mui-disabled + .MuiSwitch-track': {
       opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
     },
   },
   '& .MuiSwitch-thumb': {
     boxSizing: 'border-box',
     width: 22,
     height: 22,
   },
   '& .MuiSwitch-track': {
     borderRadius: 26 / 2,
     backgroundColor: '#ababab',
     opacity: 1,
     transition: theme.transitions.create(['background-color'], {
       duration: 500,
     }),
   },
 }));