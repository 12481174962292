import {
   SET_THEME_MODE,
} from '../actions';

export default ( state = {
   themeMode: localStorage.getItem("theme")
}, action) => {

   const {
      mode,
   } = action;

   switch (action.type) {
      case SET_THEME_MODE:
        // Save theme to Local Storage
         localStorage.setItem('theme', mode);
         {
         return {
            ...state,
            themeMode: mode,
         }
      }
      default:
            return state;
   }
}