import {
    Route,
    Redirect
  } from 'react-router-dom';
  
  function PublicRoute({ children, isAuthenticated, ...rest }) {
    return (
      <Route
        {...rest}
        render={
          ({ location }) => (
            isAuthenticated ? (
              <Redirect
              to={{
                pathname: '/',
                state: { from: location }
              }}
            />
            )
            :
             (children))
        }
      />
    );
  }
  
  export default PublicRoute;