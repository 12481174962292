import {
   SET_WORDS,
} from '../actions';

export default ( state = {
   allWords: [],
}, action) => {

   const {
      words,
   } = action;

   switch (action.type) {
      
      case SET_WORDS: {
         return {
            ...state,
            allWords: words
         }
      }

      default:
            return state;
   }
}