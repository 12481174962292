import {
    TOGGLE_MENU,
 } from './index';
 
 
 export const setIsMenuOpen = (payload) => {
    const { isMenuOpen } = payload;
    return {
        type: TOGGLE_MENU,
        isMenuOpen
    }
 }