import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import ButtonBase from '@mui/material/ButtonBase';
import './addStudent.scss'
import { PurpleButtonSmall } from '../../buttons/buttons';
import { sendInvitation } from '../../../service/requests';
import { openNotification } from '../../../actions/notification';
import { closePopup } from '../../../actions/popup';
import {PopupTitle} from '../../title/title';
import copy from 'copy-to-clipboard';


const mapDispatchToProps = dispatch => ({
   closePopup: () => {
      return dispatch(closePopup());
   },
   openNotification: (payload) => {
      return dispatch(openNotification(payload));
   }
 });

const AddStudent = (props) => {

   const {
      info,
      closePopup,
      openNotification
   } = props;

   const [contentType, setContentType] = useState('email');
   const [email, setEmail] = useState('');
   const [link, setLink] = useState('');
   const [emailError, setEmailError] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [currentDomain, setCurrentDomain]= useState();


   const onSendInvitation = async () => {
      const res = await sendInvitation(info.classId, {link: true});
      setLink(res.data.code);
    };

   useEffect( () => {
      if(!link.length) {
         onSendInvitation()
      }
      setCurrentDomain(window.location.origin)
   }, []);

   const onCopyLink = () => {
      copy(`${currentDomain}/invite/${link}`);
      openNotification({text: 'Copied!', notificationType: 'info' });
   }

   const validation = () => {
      if (email !== null) {
         let lastAtPos = email.lastIndexOf('@');
         let lastDotPos = email.lastIndexOf('.');

         if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') == -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
            setEmailError(true);
            return false;
         }
         return true;
      }
   }

   const addStudent = async () => {
      if (validation()) {
         setEmailError(false);
         setIsLoading(true);
         const res = await sendInvitation(info.classId, {email: email});
         if(res.data.ok) {
            setIsLoading(false);
            openNotification({text: 'Invitation was send!', notificationType: 'info' });
            closePopup();
         }
      } else {
         setIsLoading(false)
         console.log('not valild')
      }
   }

   return (
      <section className="my-6 mx-5 md:mx-20">
         <PopupTitle title="Add Student"/>

         <div className="flex gap-5 mx-auto w-max mt-5">
            <div className={contentType === 'email' ? "addStudent__switchBtn--active" : "addStudent__switchBtn"} onClick={() => setContentType('email')}>E-mail</div>
            <div className={contentType === 'link' ? "addStudent__switchBtn--active" : "addStudent__switchBtn"} onClick={() => setContentType('link')}>Link</div>
         </div>

         <div className="flex flex-col mt-7 relative">
            {contentType === 'email'
               ? <>
                  <label className="text-gray font-light text-sm">Email</label>
                  <input type="text" className={emailError ? "popupInput--error" : "popupInput" }
                     value={email}
                     autoFocus={true}
                     placeholder="Enter student email"
                     onChange={(e) => {setEmail(e.target.value); setEmailError(false)}}
                  ></input>
                     {emailError && <div className="auth__textError">Invalid Email</div>}
               </>
               :
               <>
                  <label className="text-gray font-light text-sm">Copy Link</label>
                  <div className='addStudent__inputWrapper flex-col sm:flex-row p-1 pl-1 sm:pl-3'>
                  <input type="text" className=" w-full mr-3" value={currentDomain ? `${currentDomain}/invite/${link}` : 'loading ...'}></input>
                  <div className='mt-2 sm:mt-0'>
                     <PurpleButtonSmall onClick={onCopyLink} isLoading={!currentDomain} text="Copy Link"/>
                  </div>
                  </div>
               </>
               
            }
         </div>

        <div className="mx-auto w-min rounded-lg overflow-hidden mt-10">
         {contentType === 'email' && 
            <PurpleButtonSmall 
            text="Add" 
            onClick={addStudent}
            isLoading={isLoading} 
         /> 
         }
         </div> 

      </section>
   )
}

export default connect(
   null,
   mapDispatchToProps
 )(AddStudent);
