import {
    SET_THEME_MODE,
 } from './index';
 
 
 export const setThemeMode = (payload) => {
    const { mode } = payload;
    return {
        type: SET_THEME_MODE,
        mode 
    }
 }