import React from "react";
import ReactDOM from "react-dom/client";
import {Provider} from "react-redux";
import store from "./store";
import "./index.css";
import App from "./App";
import {BrowserRouter as Router} from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import {GoogleOAuthProvider} from "@react-oauth/google";
// import { ThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles';

// const theme = createMuiTheme();
// const useStyles = makeStyles((theme) => {
//   root: {
//     // some CSS that accesses the theme
//   }
// });


// import { ThemeProvider, createTheme } from '@mui/material/styles';

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: '#000',
//     },
//   },
// });

import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({

  palette: {
    primary: {
      main:'#6A75CA'
    },
    secondary: {
      main: '#CD2626'
    }
  }

  // palette: {
  //   white: '#ffffff',
  //   red: '#CD2626',
  //   primary: '#6A75CA'
  // },
});


const root = ReactDOM.createRoot(
  document.getElementById('root')
);
root.render(
  <Provider store={store}>
  <React.StrictMode>
    <Router>
    <GoogleOAuthProvider clientId="119326919458-efacc3jlri69hh9erltn4cae3q3kh8mc.apps.googleusercontent.com">
    <ThemeProvider theme={theme}>
        <App />
        </ThemeProvider>
        </GoogleOAuthProvider>
    </Router>
  </React.StrictMode>
</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();