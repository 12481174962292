import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import "./chooseRole.scss";
import { PurpleButtonWithLoading } from "../../components/buttons/buttons";
import ReactCountryFlag from "react-country-flag";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useHistory, useLocation } from "react-router-dom";
import Lottie from 'lottie-react';

// api
import { getUser, getCollections, createUser, googleAuthContinue, getLocation } from "../../service/requests";

// animated logo
import AnimatedLogo from '../../img/logorwAnimatedLottie.json';

// ico
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';

// actions
import { currentUser } from "../../actions/currentUser";
import { setCollections } from "../../actions/collections";


const mapStateToProps = (state) => ({
  ...state.languages
});

const mapDispatchToProps = (dispatch) => ({
  currentUser: (payload) => {
    return dispatch(currentUser(payload));
  },
  setCollections: (payload) => {
    return dispatch(setCollections(payload));
  },
});

const ChooseRole = (props) => {
  const { currentUser, setCollections, languages } = props;
  const userLang = navigator.language || navigator.userLanguage;
  const history = useHistory();
  const location = useLocation();
  const [role, setRole] = useState("student");
  const [value, setValue] = useState();
  const [inputValue, setInputValue] = useState("");
  const [user, setUser] = useState();
  const [isGoogleAuth, setIsGoogleAuth] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (languages.length) {
      setValue(languages.find(x => x.code === userLang.split('-')[0]))
    }
  }, [languages])

  useEffect(() => {
    if (location.state) {
      setUser(location.state.user);
      setIsGoogleAuth(location.state.isGoogleAuth);
    }
  }, []);

  const onNext = async () => {
    setIsLoading(true);
    if (isGoogleAuth) {
      let userInfo = { role: role, nativeLang: value.code };
      try {
        const res = await googleAuthContinue(userInfo);
        console.log("res", res);

        if (res.data.ok) {
          localStorage.setItem("accessToken", res.data.token);
          currentUser({ user: res.data.user });
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      let updatedUser = { ...user, ...{ role: role, nativeLang: value.code } };

      try {
        const resCreatedUser = await createUser(updatedUser);
        localStorage.setItem("accessToken", resCreatedUser.data.token);

        const resUser = await getUser();
        currentUser({ user: resUser.data.user });

        let invitationCode = localStorage.getItem("invitationCode");
        if (invitationCode) {
          setTimeout(() => {
            history.push(`/invite/${invitationCode}`);
          }, 0);
        } else {
          history.push("/");
        }

        const resCollections = await getCollections();
        setCollections({ collections: resCollections.data.collections });
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <div className="authWrapper">
      <Lottie className="w-28 sm:w-44 mx-auto" animationData={AnimatedLogo} loop={false} autoPlay={false} />
      <div className="chooseRole relative">
        {isLoading && (
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <CircularProgress size={100} />
          </div>
        )}

        <div className={isLoading ? "opacity-10 pointer-events-none" : ""}>
          <div className="text-dark dark:text-whitish text-center text-lg mt-10 sm:mt-14 font-light">Choose your role</div>
          <div className="chooseRole__container">
            <div
              className={role === "student" ? "chooseRole__role--active" : "chooseRole__role"}
              onClick={() => setRole("student")}
            >
              <img src="/images/ico/check.svg" alt="checkIcon" className="chooseRole__checkRoleIcon" />
              <img src="/images/ico/studentRole.svg" alt="Student icon" />
              <div className="chooseRole__roleName">Student</div>
            </div>

            <div
              className={role === "teacher" ? "chooseRole__role--active" : "chooseRole__role"}
              onClick={() => setRole("teacher")}
            >
              <img src="/images/ico/check.svg" alt="checkIcon" className="chooseRole__checkRoleIcon" />
              <img src="/images/ico/teacherRole.svg" alt="Student icon" />
              <div className="chooseRole__roleName">Teacher</div>
            </div>

            <div className="chooseRole__disabled" onClick={() => setRole("organization")}>
              {/* <div  className={role === 'organization' ? "chooseRole__role--active" : "chooseRole__role" }  onClick={() => setRole("organization")}> */}
              <img src="/images/ico/check.svg" alt="checkIcon" className="chooseRole__checkRoleIcon" />
              <img src="/images/ico/organizationRole.svg" alt="OrganizationRole icon" />
              <div className="chooseRole__roleName">Organization</div>
            </div>
          </div>

          <div className="text-dark dark:text-whitish text-center text-lg mt-10 sm:mt-14 font-light">
            Select your native language
          </div>
          {value &&
            <Autocomplete
              options={languages}
              getOptionLabel={(option) => option.name}
              id="language"
              value={value}
              onChange={(event, newValue) => setValue(newValue)}
              renderOption={(props, option, state) => (
                <div className='bg-purple p-5' {...props}>
                  <ReactCountryFlag countryCode={option.flag} svg />
                  <span className="ml-3 text-dark dark:text-white">{option.name}</span>
                </div>
              )}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
              className="chooseRole__inputWrapper"
              popupIcon={<ArrowDropDownRoundedIcon className="text-purple" />}
              disableClearable
              renderInput={(params) => {
                console.log("12", params);
                let flagCode;
                if (
                  languages.find((x) => x.name === params.inputProps.value) &&
                  languages.find((x) => x.name === params.inputProps.value).flag
                ) {
                  flagCode = languages.find((x) => x.name === params.inputProps.value).flag;
                }

                return (
                  <div style={{ position: "relative" }}>
                    {flagCode ? (
                      <span
                        style={{
                          position: "absolute",
                          top: "-4px",
                          left: "10px",
                          transform: "translateY(50%)",
                          marginLeft: "5px",
                          zIndex: 100,
                        }}
                      >
                        <ReactCountryFlag countryCode={flagCode} svg />
                      </span>
                    ) : (
                      <span
                        style={{
                          position: "absolute",
                          top: "15px",
                          left: 0,
                          marginLeft: "15px",
                          zIndex: 100,
                          backgroundColor: "#6a75ca1b",
                          width: "17px",
                          height: "13px",
                        }}
                      ></span>
                    )}
                    <TextField {...params} className="chooseRole__input" label="" variant="outlined" />
                  </div>
                );
              }}
            />
          }

          <div className="mx-auto w-full sm:w-max mt-14">
            <PurpleButtonWithLoading text="Next" onClick={onNext} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseRole);
