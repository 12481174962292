import React, { useEffect } from "react";
import {connect} from "react-redux";
import { useHistory } from 'react-router-dom';
import notFoundIllustration from "../../img/notFoundIllustration.svg";
import { PurpleButtonSmall } from "../../components/buttons/buttons";
import {setIsNotFound} from "../../actions/pages";

const mapDispatchToProps = (dispatch) => ({
  setIsNotFound: (payload) => {
    return dispatch(setIsNotFound(payload));
  },
});

const NoFoundPage = (props) => {
  const {setIsNotFound} = props;
  const history = useHistory();
  
  useEffect(() => {
    setIsNotFound({isNotFound: false});
  }, [])

  return (
    <div className="mx-auto text-center text-dark dark:text-whitish mt-40">
      <div className="text-2xl">Sorry, page don’t found :(</div>
      <div className="mt-7 font-light">
        Error code: <span className="font-semibold text-purple">404</span>
      </div>
      <img
        className="mt-7"
        src={notFoundIllustration}
        alt="page not found illustration"
      />
      <div className="mt-10">
        <PurpleButtonSmall
          text="Back to Home"
          onClick={() => history.push("/")}
        />
      </div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(NoFoundPage);
