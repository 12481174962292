import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { PurpleButtonSmall } from '../../buttons/buttons';
import { MuiChipsInput } from 'mui-chips-input'
import './createCollection.scss';
import { addCollectionReq, addTagReq, getCollectionById, getRecentlyCollectionsReq, addTagsToCollection, getTags } from '../../../service/requests';
import { closePopup } from '../../../actions/popup';
import { addCollection, setRecentlyUsedCollections } from '../../../actions/collections';
import { addTag, setTags } from '../../../actions/tags';
import { upsertCollectionToClass } from '../../../actions/classes';
import { openNotification } from '../../../actions/notification';
import { PopupTitle } from '../../title/title';


const mapDispatchToProps = dispatch => ({
   closePopup: () => {
      return dispatch(closePopup());
   },
   addCollection: (payload) => {
      return dispatch(addCollection(payload));
   },
   addTag: (payload) => {
      return dispatch(addTag(payload));
   },
   setTagsAction: (payload) => {
      return dispatch(setTags(payload));
   },
   openNotification: (payload) => {
      return dispatch(openNotification(payload));
   },
   setRecentlyUsedCollections: (payload) => {
      return dispatch(setRecentlyUsedCollections(payload));
   }
})


const CreateCollection = (props) => {

   const {
      closePopup,
      addCollection,
      openNotification,
      addTag,
      setTagsAction,
      info,
      setRecentlyUsedCollections
   } = props;

   const [collection, setCollection] = useState({ name: "" })
   const [tags, setTags] = useState([])
   const [nameError, setNameError] = useState(false);
   const [isLoading, setIsLoading] = useState(false);

   useEffect(() => {
      console.log("tags:", tags)
   }, [tags])


   useEffect(() => {
      if (info && info.classId) {
         setCollection({ ...collection, classId: info.classId })
      }
   }, [])

   const onAddTag = (tag) => {
      setTags([...tags, tag])
      // setErrorMessage("")
   }


   const handleDeleteTag = (tag) => {
      setTags([...tags.filter(x => x !== tag)])
   }



   const getRecentCollections = async () => {
      try {
         const res = await getRecentlyCollectionsReq();
         if (res) {
            console.log('res.data.collections', res.data.collections)
            setRecentlyUsedCollections({ collections: res.data.collections })
         }
      } catch (e) {
         console.log('error', e)
      }
   }

   const createCollection = async () => {
      if (!collection.name) {
         setNameError(true)
         return;
      }

      try {
         setIsLoading(true);
         const respCollection = await addCollectionReq(collection);
         if (tags.length > 0) {
            await addTagsToCollection(respCollection.data.collection._id, { createTags: tags });
            const resTags = await getTags();
            setTagsAction({ tags: resTags.data.tags });
         }

         const respCurrentCollection = await getCollectionById(respCollection.data.collection._id);
         addCollection({ collection: respCurrentCollection.data.collection });
         getRecentCollections();
         if (respCurrentCollection.data.collection.classId) {
            //    upsertCollectionToClass({collection :respCurrentCollection.data.collection   });
            info.addClassCollection(respCurrentCollection.data.collection)
         }



         closePopup();
         openNotification({ text: 'Collection added successfully', notificationType: 'success' });

      }

      catch (error) {
         setIsLoading(false);
         openNotification({ text: 'Something went wrong', notificationType: 'error' });
      }
   }

   return (
      <section className="my-6 mx-5 md:mx-20">
         <PopupTitle title="Create collection" />
         <div className="flex flex-col mt-3">
            <label className="text-textGray dark:text-darkModeGray font-light text-sm">Name</label>
            <input type="text" id="name" autoFocus={true} className={nameError ? "popupInput--error" : "popupInput"} onChange={e => setCollection({ ...collection, name: e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1) })} />
         </div>

         <div className="flex flex-col mt-3">
            <label className="text-textGray dark:text-darkModeGray font-light text-sm">Tags</label>
            <MuiChipsInput size="small" className="chipInput" value={tags} onChange={(tags) => setTags(tags)} />
            <div className="text-gray text-xs mt-2">Start type and press Enter to confirm tag</div>
         </div>

         <div className="flex mt-2 mb-4 flex-wrap">
         </div>
         <div className={isLoading && 'pointer-events-none'}>
            <div className="mx-auto w-min rounded-lg overflow-hidden">
               <PurpleButtonSmall isLoading={isLoading} text="Create" onClick={createCollection} />
            </div>
         </div>
      </section>
   )
}

export default connect(
   null,
   mapDispatchToProps
)(CreateCollection);
