import {
   SET_LANGUAGES
} from '../actions';

export default ( state = {
   languages: [],
}, action) => {

   const {
      languages
   } = action;

   switch (action.type) {
      
      case SET_LANGUAGES: {
         return {
            ...state,
            languages: languages
         }
      }
      default:
            return state;
   }
}