import { combineReducers } from 'redux';
import popups              from './popup';
import currentUser         from './currentUser';
import collections         from './collections';
import tags                from './tags';
import selectionWindow     from './selectionWindow';
import classes             from './classes';
import search              from './search';
import notifications       from './notification';
import words               from './words';
import speakerLangs        from './speakerLangs';
import themeMode           from './themeMode';
import pages               from './pages';
import languages           from './languages';
import menu                from './menu';

const rootReducer = combineReducers({
    popups,
    currentUser,
    collections,
    tags,
    selectionWindow,
    classes,
    search,
    notifications,
    words,
    speakerLangs,
    themeMode,
    pages,
    languages,
    menu
});


export default (state, action) =>
  rootReducer(action.type === 'USER_LOGOUT' ? undefined : state, action);