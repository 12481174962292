import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import './uploadImage.scss';
import { PurpleButtonSmall, DisableButtonSmall, EmptyButtonSmall } from '../../../components/buttons/buttons';
import { addUserPhoto, getUser } from '../../../service/requests';
import ButtonBase from '@mui/material/ButtonBase';
import Slider from '@mui/material/Slider';
import {PopupTitle} from '../../title/title';

import Cropper from 'react-easy-crop';
import getCroppedImg from './cropImage';

// img 
import illustration from '../../../img/uploadImageIllustration.svg';

// actions 
import { currentUser } from '../../../actions/currentUser';
import { closePopup } from '../../../actions/popup';
import { openNotification } from '../../../actions/notification';

const mapDispatchToProps = dispatch => ({
   closePopup: () => {
      return dispatch(closePopup());
   },
   currentUser: (payload) => {
      return dispatch(currentUser(payload));
   },
   openNotification: (payload) => {
      return dispatch(openNotification(payload));
   }
})

const UploadImage = (props) => {

   const {
      info,
      closePopup,
      currentUser,
      openNotification
   } = props;

   const [image, setImage] = useState();
   const [zoom, setZoom] = useState(1)
   const [isLoading, setIsLoading] = useState(false);

   const [crop, setCrop] = React.useState({ x: 0, y: 0 })
   const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null)

   const onImageChange = (e) => {
      setImage(URL.createObjectURL(e.target.files[0]));
   }

    // for cropping image

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels)
   }, [])

   const uploadImage = async (croppedImage) => {
      try {
         const formData = new FormData();
         let blob = await fetch(croppedImage).then(r => r.blob());
         formData.append("file", blob, `${info.userId}.jpg`);
         let res = await addUserPhoto(formData);
         openNotification({text: 'Your photo updated successfully', notificationType: 'success' });
         closePopup()

        const resp = await getUser().catch(console.log);
        currentUser({ user: resp.data.user });
      } catch (e) {
         console.error(e)
      }
      setIsLoading(false);
   }

   const showCroppedImage = useCallback(async () => {
      try {
         setIsLoading(true)

         const croppedImage = await getCroppedImg(
            image,
            croppedAreaPixels
         )
         uploadImage(croppedImage);
      } catch (e) {
         console.error(e)
      }

   }, [croppedAreaPixels])


   const onCancel = () => {
      if(!image) {
         closePopup()
      }
      setCroppedAreaPixels(null);
      setImage(null);
   }

   return (
      <section className="my-6 mx-5 md:mx-20">
         <PopupTitle title="Upload Image"/>


         <div className="mt-7">
            <div className="uploadImage__innerWrapper">
               {!image &&
               <div>
               <div className="p-3 w-max mx-auto">
                  <img src={illustration} className="w-40" />
               </div>
               <div className="w-36 text-dark dark:text-white text-center mx-auto my-5 text-sm">Drop your file here or <span className="text-purple dark:text-orange">Browse</span></div>
               </div>
               }
               {/* {isLoading &&  
               <div className="w-40 my-5 mx-auto">
                  <div className="w-36 text-center mx-auto mt-2 text-sm text-purple">Uploading...</div>
                </div>
               } */}

               <input
                  type="file"
                  name="file"
                  onChange={onImageChange}
                  accept="image/x-png,image/gif,image/jpeg"
                  className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
               />

               {image &&
                  <>
                     <Cropper
                        image={image}
                        crop={crop}
                        zoom={zoom}
                        aspect={1 / 1}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        cropShape='round'
                        showGrid={false}
                     />

                     <div className="uploadImage__zoomSlider">
                        <Slider
                           value={zoom}
                           min={1}
                           max={3}
                           step={0.1}
                           aria-labelledby="Zoom"
                           onChange={(e, zoom) => setZoom(zoom)}
                           classes={{ root: 'slider' }}
                        />
                     </div>
                  </>
               }


            </div>



            <div className="mt-10 flex w-max mx-auto">
            {image && !isLoading
               ? <PurpleButtonSmall isLoading={isLoading} text="Update" onClick={showCroppedImage}/>
               : <DisableButtonSmall text="Update"/>
            }
               <div className="ml-3">
                  <EmptyButtonSmall onClick={onCancel} text="Cancel"/>
                  {/* <ButtonBase><div className="rounded-lg mx-auto text-xs text-purple py-2 px-8 w-max cursor-pointer hover:bg-brightLightPurple border border-purple transition" onClick={onCancel}>Cancel</div></ButtonBase> */}
               </div>
            </div>
         </div>

      </section>
   )
}

export default connect(
   null,
   mapDispatchToProps
)(UploadImage);