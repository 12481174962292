import {
    CHECK_LAST_ELEM,
    SET_SEARCH_WORD,
    SET_PAGE,
    SET_PAGE_NAME,
    SET_DATA_LOADER
} from '../actions';

export default ( state = {
   searchWord: null,
   page: 0,
   checkedLastElem: false,
   isMore: false,
   isDataLoading: false
},
 action) => {

    const {
        searchWord,
        page,
        checkedLastElem,
        pageName,
        isDataLoading
    } = action;

    switch (action.type) {
        case SET_SEARCH_WORD:
            {
                return {
                    ...state,
                    searchWord: searchWord,
                }
            }

        case SET_PAGE:
            {
                return {
                    ...state,
                    page: page,
                }
            }


        case CHECK_LAST_ELEM:
            {
                return {
                    ...state,
                    checkedLastElem: checkedLastElem,
                }
            }

        case SET_PAGE_NAME:
            {
                return {
                ...state,
                    pageName: pageName,
                }
            }

        case SET_DATA_LOADER:
            {
                return {
                ...state,
                isDataLoading: isDataLoading,
                }
            }

        default:
            return state;
    }
}