import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { PurpleButtonSmall, DisableButtonSmall } from '../../buttons/buttons';
import {PopupTitle} from '../../title/title';
import { closePopup } from '../../../actions/popup';
import { updateClassReq, getClassById } from '../../../service/requests';
// import { updateClass } from '../../../actions/classes';
import { openNotification } from '../../../actions/notification';
import LanguageSelector from '../../languageSelector/languageSelector';


const mapStateToProps = (state) => ({
   ...state.currentUser,
 });

const mapDispatchToProps = dispatch => ({
   closePopup: () => {
      return dispatch(closePopup());
   },
   // updateClass: (payload) => {
   //    return dispatch(updateClass(payload));
   // },
   openNotification: (payload) => {
      return dispatch(openNotification(payload));
   },
})


const EditClass = (props) => {

   const {
      closePopup,
      info,
      user,
      openNotification,
   } = props;

   const [clas, setClas] = useState({ name: info.clas.name , lang: info.clas.lang, learnLang : info.clas.learnLang})
   const [isLoading, setIsLoading] = useState(false);
   const [isDisabledSaveBtn, setIsDisabledSaveBtn] = useState(true);

   useEffect(() => {
      const {name, lang, learnLang} = info.clas;
      if(name != clas.name || lang != clas.lang || learnLang != clas.learnLang) {
         setIsDisabledSaveBtn(false);
      }
   }, [clas])

   const onUpdateClass = async () => {


      try {
         setIsLoading(true)
         const res = await updateClassReq(info.clas._id, clas);
         if(res.data.ok) {
            try {
               const resClass = await getClassById(info.clas._id);
               if(resClass.data.ok) {
                  info.onUpdateClass(resClass.data.class);
                  openNotification({text: 'Class updated successfully', notificationType: 'success' });
                  closePopup();
               }
            } catch(e) {
               openNotification({text: 'Something went wrong', notificationType: 'error' });
            }
         }
      }

      catch (error) {
         setIsLoading(false)
         openNotification({text: 'Something went wrong', notificationType: 'error' });
         console.log(error)
      }
   }

   return (
      <section className="my-8 mx-5 sm:mx-20">
      <PopupTitle title="Edit Class"/>

         <div className="flex flex-col mt-7 relative">
            <label className="text-gray font-light text-sm">Name</label>
            <input className="popupInput" onChange={(e) =>  setClas({...clas, name: e.target.value})} defaultValue={clas.name}></input>
         </div>

         <div className="flex flex-col mt-3">
            <label className="text-gray font-light text-sm">Class language (translate from)</label>
            <div className='mt-1.5'>
               <LanguageSelector lang={clas.learnLang} setLang={(lang) =>  setClas({...clas, learnLang: lang})} />
            </div>
         </div>

         <div className="flex flex-col mt-3">
            <label className="text-gray font-light text-sm">Students' native language (translate to)</label>
            <div className='mt-1.5'>
               <LanguageSelector lang={clas.lang} setLang={(lang) =>  setClas({...clas, lang: lang})}/>
            </div>
         </div>

         <div className="mx-auto w-min rounded-lg overflow-hidden mt-10">
            {isDisabledSaveBtn ? 
            <DisableButtonSmall text="Update" /> 
            :
            <PurpleButtonSmall isLoading={isLoading} text="Update" onClick={onUpdateClass} /> 
            }
         </div>

      </section>
   )
}

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(EditClass);
