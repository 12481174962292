import React, { useState } from "react";
import { connect } from "react-redux";
import { closePopup } from "../../../actions/popup";
import { openNotification } from "../../../actions/notification";
import {
  PurpleButtonSmall,
  NextStepButton,
} from "../../buttons/buttons";

import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import lockCloseIcon from "../../../img/ico/lockCloseIcon.svg";
import lockOpenIcon from "../../../img/ico/lockOpenIcon.svg";
import { createPassword } from "./../../../service/requests";
import { currentUser } from '../../../actions/currentUser';

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => {
    return dispatch(closePopup());
  },
  openNotification: (payload) => {
    return dispatch(openNotification(payload));
  },
  currentUser: (payload) => {
    return dispatch(currentUser(payload));
 }
});

const CreatePassword = (props) => {
  const {
    closePopup,
    openNotification,
    info,
    currentUser
  } = props;


  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");

  const [passwordError, setPasswordError]= useState({isError: false, errorText: ''});

  const [showPassword, setShowPassword] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  
  const validate = () => {
    if(password && password === passwordRepeat){
      return true;
    } else {
      return false;
    }
  }


  const onPasswordChange = async () => {
    const isValid = validate();

    if(isValid) {
      try {

        const res = await createPassword({
          password: password,
        });


        if(res.response && !res.response.data.ok){
          openNotification({text: 'Something went wrong', notificationType: 'error' });
          setPasswordError({isError: true, errorText: 'Something went wrong' });
        }

        if(res.data.ok) {
          closePopup();
          openNotification({text: 'Password was added successfully', notificationType: 'success' });
          const updatedUser = {...info.user, hasPass: true}
          currentUser({ user: updatedUser });
        } 

      } catch(e) {
        console.log('e', e)
      }
      
    } else {
      setPasswordError({isError: true, errorText: 'Passwords do not match' });
    }
  };


  return (
    <section className="my-8 mx-20">
      <img
        src={showPassword ? lockOpenIcon : lockCloseIcon}
        className="m-auto"
      />
      <div className="mt-5 text-center text-dark dark:text-white font-light text-sm">
        Create Password
      </div>

      <div className="mt-3 text-dark dark:text-white text-xl font-medium text-center">
        Enter your password
      </div>


      {passwordError.isError &&  <div className="w-9/12 mx-auto absolute bottom-20"><div className="text--error w-9/12 mx-auto">{passwordError.errorText}</div></div>}

          <div className="w-9/12 mx-auto mt-8">
            <Input
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              value={password}
              fullWidth
              autoFocus={true}
              error={passwordError.isError}
              placeholder="Enter new password"
              className="text-dark dark:text-white"
              onChange={(e) => {setPassword(e.target.value); setPasswordError({isError: false, errorText: ""})}}

              endAdornment={
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={(e) => handleMouseDownPassword(e)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOffIcon className="text-purple dark:text-white" /> : <VisibilityIcon className="text-purple dark:text-white" />}
                </IconButton>
              }
              label="Password"
            />
          </div>
          <div className="w-9/12 mx-auto mt-8">
            <Input
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              value={passwordRepeat}
              fullWidth
              className="text-dark dark:text-white"
              error={passwordError.isError}
              placeholder="Repeat new password"
              onChange={(e) => {setPasswordRepeat(e.target.value); setPasswordError({isError: false, errorText: ""})}}

              endAdornment={
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={(e) => handleMouseDownPassword(e)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOffIcon className="text-purple dark:text-white" /> : <VisibilityIcon className="text-purple dark:text-white" />}
                </IconButton>
              }
              label="Password"
            />
          </div>

      <div className="mx-auto w-max mt-10 flex gap-5">
          <PurpleButtonSmall
            text="Confirm"
            onClick={() => onPasswordChange()}
          />
      </div>
    </section>
  );
};

export default connect(null, mapDispatchToProps)(CreatePassword);