import {
    SET_IS_NOT_FOUND,
 } from './index';
 
 
 export const setIsNotFound = (payload) => {
    const { isNotFound } = payload;
    return {
        type: SET_IS_NOT_FOUND,
        isNotFound, 
    }
 }