import React, { useEffect, useState, useRef,useMemo } from 'react';
import './header.scss';
import { connect } from 'react-redux';
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { baseURL } from './.././../service/requests';

// icons
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';

// images
import userImg from '../../img/ico/user.svg'
import searchIcon from '../../img/ico/searchIcon.svg'
import { useParams } from "react-router-dom";

//search
// import { setSearchWord, setPage, setIsMore, checkLastElem, setCollectionLoader } from '../../actions/search';
import { setSearchWord, checkLastElem, setDataLoader } from '../../actions/search';
import { setCollections } from '../../actions/collections';
import { setWords } from '../../actions/words';
import { setIsMenuOpen } from '../../actions/menu';

import { debounce } from 'lodash';

const mapStateToProps = state => ({
  ...state.currentUser,
  ...state.menu
});

const mapDispatchToProps = dispatch => ({
  setSearchWord: (payload) => {
    return dispatch(setSearchWord(payload));
  },
  setCollections: (payload) => {
    return dispatch(setCollections(payload));
  },
  checkLastElem: (payload) => {
    return dispatch(checkLastElem(payload));
  },
  setDataLoader: (payload) => {
    return dispatch(setDataLoader(payload));
  },
  setWords:(payload) => {
    return dispatch(setWords(payload));
  },
  setIsMenuOpen:(payload) => {
    return dispatch(setIsMenuOpen(payload));
  },
})

const Header = (props) => {

  const {
    user,
    setSearchWord,
    setCollections,
    setWords,
    setIsMenuOpen,
    isMenuOpen
  } = props;

  // const getUserPhoto = (imagePath) => {
  //   if (imagePath) {
  //     if (imagePath.includes("http") || imagePath.includes("https")) {
  //       return   imagePath;
  //     } else {
  //       return `${baseURL}images/users/${imagePath}`;
  //     }
  //   } else {
  //     return userImg;
  //   }
  // };
  
  // const organizations = [
  //   { name: 'Green Forest', logo: 'https://images.pexels.com/photos/6476254/pexels-photo-6476254.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260' },
  //   { name: 'Hello Hello', logo: 'https://images.pexels.com/photos/6476254/pexels-photo-6476254.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260' },
  //   { name: 'English Lab', logo: 'https://images.pexels.com/photos/6476254/pexels-photo-6476254.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260' },
  //   { name: "Lemon Studio", logo: 'https://images.pexels.com/photos/6476254/pexels-photo-6476254.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260' }
  // ];
  const [searchVal, setSearchVal] = useState('');
  const [userAvatarUrl, setUserAvatarUrl] = useState('../images/ico/user.svg');
  const location = useLocation();
  const history = useHistory();
  const [prevRoutePath, setPrevRoutePath] = useState(location.pathname);
  const latestPrevRoutePath = useRef(prevRoutePath);
  // const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);


  useEffect(() => {
    // clear alert on location change
    const unlisten = history.listen((route) => {
      if (route.pathname != latestPrevRoutePath.current) {

        setPrevRoutePath(prev => {
          latestPrevRoutePath.current = route.pathname;
          return route.pathname;
        });

        setSearchVal("");
        setSearchWord({searchWord: null});

        // delete all data
        setCollections({collections: []});
        setWords({words: []});
      }
    });

    // stop the listener when component unmounts
    return unlisten;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearchInputChange = (val) => {
    setSearchWord({searchWord: val});
  };

  const debouncedEventHandler = useMemo(
    () => debounce(onSearchInputChange, 500)
  , []);

  const isSearchShown = () => {
    const routesWithSearch = ['/collections', '/class', '/practice', '/classes', '/collection'];
      let isSearchable = routesWithSearch.some(x=> location.pathname.indexOf(x) >= 0);

      if(location.pathname.includes("practice/")){
        isSearchable = false;
      }

      console.log('isSearchable', isSearchable)
      return isSearchable;
  }

  useEffect(() => {
    if(user && user.avatar) {
      const userAvatar = user.avatar;
      if (userAvatar.includes("http") || userAvatar.includes("https")) {
        setUserAvatarUrl(userAvatar)
      } else {
        setUserAvatarUrl(`${baseURL}images/users/${userAvatar}`)
      }
    }
  }, [user])

  const toggleMenu = () => {
    // setIsOpenMobileMenu(!isOpenMobileMenu);
    setIsMenuOpen({ isMenuOpen: !isMenuOpen });
};
  
  return (
    <>
    <div className={!isSearchShown() ? "bg-bgLightMode dark:bg-bgDarkMode z-50 flex w-full fixed md:relative gap-5 justify-between items-center py-2 md:pt-11 px-5 md:px-10 lg:px-20" : "bg-bgLightMode dark:bg-bgDarkMode fixed md:relative flex gap-5 z-50 w-full justify-between items-center py-2 md:pt-11 px-5 md:px-10 lg:px-20"}>
    <NavLink to="/" className="block md:hidden">
    <img className="w-12" src="/images/logo.svg"/>
 </NavLink>
      {isSearchShown() &&
      <div className="hidden md:block relative flex-1 md:flex-none">
        <input
          type="text"
          placeholder="Search"
          className="header__searchInput"
          value={searchVal}
          onChange={(e) => { debouncedEventHandler(e.target.value); setSearchVal(e.target.value) }}
          />
        <span className="header__searchIconWrapper"><img src={searchIcon} className="header__searchIcon"/></span>
      </div>
        }

      {/* {user.role === 'teacher' &&
        <div className="header__selection" ref={ref}>
          <div className="header__selectionHeader" onClick={() => setIsSelectionOpen(!isSelectionOpen)}>
            <img src={selectedOrganization.logo} className="object-cover w-7 h-7 mr-3 rounded-full" />
            <div className="mr-10">{selectedOrganization.name}</div>
            <div className="ml-auto">{isSelectionOpen ? <ArrowDropUpOutlinedIcon /> : <ArrowDropDownOutlinedIcon />}</div>
          </div>
          <div className={isSelectionOpen ? "header__selectionItems fadeIn" : "header__selectionItems--closed"}>
            {organizations.map(organization => {
              return (
                <div className="header__selectionItem" onClick={() => setSelectedOrganization(organization)}>
                  <img src={selectedOrganization.logo} className="object-cover w-7 h-7 mr-3 rounded-full" />
                  <div className="mr-10">{organization.name}</div>
                  {organization.name === selectedOrganization.name && <div className="ml-auto"><DoneRoundedIcon fontSize="small" /></div>}
                </div>
              )
            })}
          </div>
        </div>
      } */}
      <div className='flex items-center gap-3 ml-auto'>

          <NavLink to="/profile" activeClassName="header__user--active" className="header__user" >
            <div className="flex items-center">
              <div className="header__userName text-base capitalize font-light mr-3">{user.name}</div>
              <div className="header__userImgWrapper rounded-full w-12 h-12 border-2 border-lightGray overflow-hidden">
                <img src={userAvatarUrl} alt="users avatar" className="object-cover w-full h-full" />
              </div>
            </div>
          </NavLink>

          <div className={isMenuOpen ? "hamburger-iconWrapper open" : "hamburger-iconWrapper"} onClick={toggleMenu}>
            <div className="hamburger-icon">
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>
          </div>
        </div>

      </div>

    {isSearchShown() &&
      <div className="block md:hidden relative flex-1 md:flex-none mx-5 mt-16">
        <input
          type="text"
          placeholder="Search"
          className="header__searchInput"
          value={searchVal}
          onChange={(e) => { debouncedEventHandler(e.target.value); setSearchVal(e.target.value) }}
          />
        <span className="header__searchIconWrapper"><img src={searchIcon} className="header__searchIcon"/></span>
      </div>
        }

    </>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);

