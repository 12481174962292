import {
    SET_SEARCH_WORD,
    CHECK_LAST_ELEM,
    SET_PAGE,
    SET_PAGE_NAME,
    SET_DATA_LOADER
 } from './index';
 
 
 export const setSearchWord = (payload) => {
    const { searchWord, page } = payload;
    return {
        type: SET_SEARCH_WORD,
        searchWord,
    }
}

export const checkLastElem = (payload) => {
    const { checkedLastElem } = payload;
    return {
        type: CHECK_LAST_ELEM,
        checkedLastElem,
    }
}

export const setPageName = (payload) => {
    const { pageName } = payload;
    return {
        type: SET_PAGE_NAME,
        pageName,
    }
}

export const setPage = (payload) => {
    const { page } = payload;
    return {
        type: SET_PAGE,
        page,
    }
}

export const setDataLoader = (payload) => {
    const { isDataLoading } = payload;
    return {
        type: SET_DATA_LOADER,
        isDataLoading,
    }
}