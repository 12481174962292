import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactCountryFlag from "react-country-flag";
import Autocomplete from '@mui/material/Autocomplete';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import TextField from '@mui/material/TextField';

const mapStateToProps = (state) => ({
   ...state.currentUser,
   ...state.languages
});

const LanguageSelector = (props) => {

   const {
      user,
      setLang,
      lang,
      languages,
      disableAuto
   } = props;

   const [inputValue, setInputValue] = useState("");
   const [updatedLanguages, setUpdatedLanguages] = useState(languages);
   const [value, setValue] = useState(lang
      ? languages.find((x) => x.code === lang)
      : null
   );

   useEffect(() => {
      if(languages && disableAuto) {
         setUpdatedLanguages(languages.filter(x => x.name != 'Auto'));
      }
   }, [])

   return (
      <>
         <Autocomplete
            options={updatedLanguages}
            getOptionLabel={option => option.name}
            id="language"
            disableClearable
            size='small'
            value={value}
            onChange={(event, newValue) => {
               setValue(newValue);
               setLang(newValue.code);
            }}

            renderOption={(props, option, state) => (
               <div className='bg-purple p-0' {...props}>
                  <ReactCountryFlag countryCode={option.flag} svg />
                  <span className="ml-3 text-dark dark:text-whitish">{option.name}</span>
               </div>
            )}
            renderInput={(params) => {
               let flagCode;
               if (languages.find(x => x.name === params.inputProps.value) && languages.find(x => x.name === params.inputProps.value)?.flag) {
                  flagCode = languages.find(x => x.name === params.inputProps.value)?.flag;
               }

               return (
                  <div style={{ position: "relative" }}>
                     {flagCode && (
                        <span
                           className='profile__flagWrapper'
                           style={{
                              position: "absolute",
                              top: '-2px',
                              right: '40px',
                              transform: "translateY(50%)",
                              marginLeft: "5px",
                              zIndex: 100
                           }}
                        >
                           {flagCode != 'au' &&
                              <ReactCountryFlag countryCode={flagCode} svg />
                           }
                        </span>
                     )}<TextField {...params} className="plainLangSelector" label="" variant="outlined" />
                  </div>
               )
            }}
         />
      </>
   );
};

export default connect(mapStateToProps, null)(LanguageSelector);
