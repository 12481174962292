import {
    SET_IS_NOT_FOUND,
 } from '../actions';
 
 export default ( state = {
    isNotFound: false,
 }, action) => {
 
    const {
        isNotFound,
    } = action;
 
    switch (action.type) {
       case SET_IS_NOT_FOUND:
          {
          return {
             ...state,
             isNotFound: isNotFound,
          }
       }
 
       default:
             return state;
    }
 }