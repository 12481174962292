import {
   TOGGLE_MENU,
} from '../actions';

export default ( state = {
   isMenuOpen: false,
}, action) => {

   const {
      isMenuOpen,
   } = action;

   switch (action.type) {
      
      case TOGGLE_MENU: {
         return {
            ...state,
            isMenuOpen: isMenuOpen
         }
      }

      default:
            return state;
   }
}