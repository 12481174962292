import {
   SET_SPEAKER_LANGS,
} from '../actions';

export default ( state = {
   speakerLangs: false,
}, action) => {

   const {
      langs,
   } = action;

   switch (action.type) {

      case SET_SPEAKER_LANGS: {
         return {
            ...state,
            speakerLangs: langs,
         }
      }

      default:
            return state;
   }
}