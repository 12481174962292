import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from 'react-router-dom';

import { PurpleButton, EmptyButton, DisableButton } from '../../components/buttons/buttons';
import invitationIllustration from '../../img/invitationIllustration.svg';
import invitationIllustrationDarkMode from '../../img/invitationIllustration-darkMode.svg';
import { getInvitationInfo, acceptInvitation } from './../../service/requests';
import CircularProgress from '@mui/material/CircularProgress';

const mapStateToProps = state => ({
   ...state.themeMode
});

const InvitationPage = (props) => {

   const {
      themeMode
   } = props;

    let { id } = useParams();
    let history = useHistory();

    const [invitationFrom, setInvitationFrom ] = useState("");
    const [isLoading, setIsLoading] = useState(true);


    const onGetInvitationInfo = async () => {
      try {
         let response = await getInvitationInfo(id);
         setInvitationFrom(response.data.name);
         setIsLoading(false);
      }
      catch (error) {
         //show expired
         //redirect to 404 page if teacher (not existing yet)
         history.push("/logIn");
      }
    };

   useEffect( () => {
      const token = localStorage.getItem("accessToken");
      
      if(!token){
         localStorage.setItem("invitationCode", id);
         history.push("/logIn");
      }
      onGetInvitationInfo()
   }, []);

   const accept = async () => {
      localStorage.removeItem("invitationCode");
      try {
         const response = await acceptInvitation(id);
         history.push("/classes");
       }
 
       catch (error) {
         console.log(error)
         history.push("/logIn");
       }
     }

     const cancel = async () => {
      localStorage.removeItem("invitationCode");
      history.push("/classes");
     }

   return (
      <div>
            <div className="fadeIn mt-20 sm:mt-40 text-dark dark:text-whitish">
               <div className="text-center mt-14 font-medium text-xl flex justify-center">Invite from <div className="ml-2 text-purple relative">
                  {invitationFrom}
                  <div className="absolute top-0.5 left-2">{isLoading && <CircularProgress size={15}/> }</div>
                  </div>
               </div>
               {/* <div className="text-center mt-5 text-gray font-light text-dark">If you are ready to become a teacher at <span className="font-medium">Green Forest School,</span> please confirm the request!</div> */}
               <div className="text-center mt-5 text-gray dark:text-darkModeGray font-light text-dark mx-10">You have been invited to the class <span className="font-medium">{invitationFrom}</span> please confirm the request!</div>
               <img src={themeMode === 'dark' ? invitationIllustrationDarkMode : invitationIllustration} className="mx-auto mt-10" alt="We sent you a letter by mail, follow the link and create a new password" />
               <div className="mt-10 mx-auto w-min flex gap-5">
                  <EmptyButton text="Cancel" onClick={cancel}/>
                  <PurpleButton text="Confirm" onClick={accept}/>
               </div>
            </div>
      </div>
   )
}

export default connect(
   mapStateToProps,
   null
)(InvitationPage);
