import {
   SET_COLLECTIONS,
   ADD_COLLECTION,
   UPDATE_COLLECTION,
   DELETE_COLLECTION,
   SET_RECENTLY_USED_COLLECTIONS
} from '../actions';

export default ( state = {
   collections: [],
   recentlyUsedCollections: [],
}, action) => {

   const {
      collections,
      collection,
      recentlyUsedCollections
   } = action;

   switch (action.type) {
      
      case SET_COLLECTIONS: {
         return {
            ...state,
            collections: collections
         }
      }

      case SET_RECENTLY_USED_COLLECTIONS: {
         return {
            ...state,
            recentlyUsedCollections: collections
         }
      }

      case ADD_COLLECTION: {
         return {
            ...state,
            collections: [collection, ...state.collections]
         }

      }
      
      case UPDATE_COLLECTION: {
         let updatedCollection = state.collections.find(x => x._id === collection._id);
         let updatedCollectionIndex = state.collections.indexOf(updatedCollection);
         let collections = state.collections;
         collections[updatedCollectionIndex] = collection;

         return {
            ...state, 
            collections: [...collections]
         }
      }
      
      case DELETE_COLLECTION: {

         let deletedCollection = state.collections.find(x => x._id === collection);
         return {
            ...state,
            collections: [...state.collections.filter(x => x !== deletedCollection)]
         }
      }

      default:
            return state;
   }
}