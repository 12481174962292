import { lazy } from 'react';

const routes = [
  {
    path: '/',
    component: lazy(() => import('./pages/homePage/homePage')),
    exact: true
  },
  {
    path: 'collections',
    component: lazy(() => import('./pages/collections/collectionsPage')),
    exact: true
  },
  {
    path: 'collection/:collectionId',
    component: lazy(() => import('./pages/words/wordsPage')),
    exact: true
  },
  {
    path: 'profile',
    component: lazy(() => import('./pages/profile/profilePage')),
    exact: true
  },
  {
    path: 'practice/:collectionId',
    component: lazy(() => import('./pages/practice/practicePage')),
    exact: true
  },
  {
    path: 'practice',
    component: lazy(() => import('./pages/practice/practiceCollections')),
    exact: true
  },
  {
    path: 'classes',
    component: lazy(() => import('./pages/classes/classesPage')),
    exact: true
  },
  {
    path: 'class/:id',
    component: lazy(() => import('./pages/class/classPage')),
    exact: true
  },
  {
    path: 'class/:classId/:collectionId',
    component: lazy(() => import('./pages/words/wordsPage')),
    exact: true
  },
  {
    path: 'invite/:id',
    component: lazy(() => import('./pages/invitationPage/invitationPage')),
    exact: true,
    onlyStudent: true
  },
  {
    path: 'privacy',
    component: lazy(() => import('./pages/privacy/privacy')),
    exact: true
  }

  // <Route path="/invite/:id" component={InvitationPage} />

//   {
//     path: 'support',
//     component: lazy(() => import('./pages/supportPage/supportPage')),
//     exact: true
//   }

];

export default routes;