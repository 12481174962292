import {
    SET_WORDS,
 } from './index';
 
 
 export const setWords = (payload) => {
    const { collections } = payload;
    return {
        type: SET_WORDS,
        collections
    }
 }