import React from 'react'
import { connect } from 'react-redux';
import { PurpleButtonSmall, EmptyButtonSmall } from '../../../components/buttons/buttons';
import {PopupTitle} from '../../title/title';
import { deleteClassReq } from '../../../service/requests';
import { closePopup } from '../../../actions/popup';
import { deleteClass } from '../../../actions/classes';
import { openNotification } from '../../../actions/notification';


const mapDispatchToProps = dispatch => ({
   closePopup: () => {
      return dispatch(closePopup());
   },
   deleteClass: (payload) => {
      return dispatch(deleteClass(payload));
   },
   openNotification: (payload) => {
      return dispatch(openNotification(payload));
   },
})

const DeleteClass = (props) => {

   const {
      closePopup,
      deleteClass,
      info,
      openNotification
   } = props;



   const onClassDelete = async () => {
      console.log('classss frpm leave clas', info.clas._id, info.clas.name)
      
      try {
         await deleteClassReq(info.clas._id);
         info.onDeleteClass(info.clas._id);
         openNotification({text: 'Class removed successfully', notificationType: 'success' });
      }

      catch (error) {
         openNotification({text: error.response.data?.message, notificationType: 'error' });
      }
      closePopup()
   }

   return (
      <section className="my-10 mx-5 md:mx-20">
         <PopupTitle title="Delete class?"/>

         <div className="mt-8 text-center text-textGray font-light">
   Are you sure you want to delete a <b>{info.clas.name}</b> class?
         </div>

         <div className="mx-auto w-max mt-12 flex gap-5">
            <EmptyButtonSmall text="Cancel" onClick={() => closePopup()} />
            <PurpleButtonSmall text="Yes, delete" onClick={() => onClassDelete()} />
         </div>

      </section>
   )
}

export default connect(
   null,
   mapDispatchToProps
)(DeleteClass);
