import {
   SET_CLASSES,
   ADD_CLASS,
   UPDATE_CLASS,
   DELETE_CLASS,
   UPSERT_COLLECTION_TO_CLASS
} from './index';


export const setClasses = (payload) => {
   const { classes } = payload;
   return {
       type: SET_CLASSES,
       classes
   }
}

export const addClass = (payload) => {
   const { clas } = payload;
   return {
       type: ADD_CLASS,
       clas
   }
}

export const updateClass = (payload) => {
   const { clas } = payload;
   return {
       type: UPDATE_CLASS,
       clas
   }
}

export const deleteClass = (payload) => {
   const { clas } = payload;
   return {
       type: DELETE_CLASS,
       clas
   }
}

export const upsertCollectionToClass = (payload) => {
   const { collection } = payload;
   return {
       type: UPSERT_COLLECTION_TO_CLASS,
       collection
   }
}