import {
   SET_CLASSES,
   ADD_CLASS,
   UPDATE_CLASS,
   DELETE_CLASS,
   UPSERT_COLLECTION_TO_CLASS
} from '../actions';

export default ( state = {
   classes: [],
}, action) => {

   const {
      classes,
      clas,
      collection
   } = action;

   switch (action.type) {
      
      case SET_CLASSES: {
         return {
            ...state,
            classes: classes
         }
      }

      case ADD_CLASS: {
         return {
            ...state,
            classes: [...state.classes, clas]
         }

      }

      case UPDATE_CLASS: {
         let updatedClass = state.classes.find(x => x._id === clas._id);
         let updatedClassIndex = state.classes.indexOf(updatedClass);
         let classes = state.classes;
         classes[updatedClassIndex] = clas;

         return {
            ...state, 
            classes: [...classes]
         }
      }

      
      case DELETE_CLASS: {

         let deletedClass = state.classes.find(x => x._id === clas);
         return {
            ...state,
            classes: [...state.classes.filter(x => x !== deletedClass)]
         }

      }

      case UPSERT_COLLECTION_TO_CLASS: {

         let updatedClass = state.classes.find(x => x._id === collection.classId);

         let upsertedCollection = updatedClass.collections.find(x=> x._id === collection._id);
         if(!upsertedCollection){
            updatedClass.collections.push(collection);
         }
         else {
            let index = updatedClass.collections.findIndex(x=>x._id === collection._id);
            updatedClass.collections[index] = collection;
         }


         return {
            ...state, 
            classes: [...state.classes.filter(x => x !== updatedClass), updatedClass]
         }

      }

      default:
            return state;
   }
}