import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import './editCollection.scss';

import ButtonBase from '@mui/material/ButtonBase';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import {PopupTitle} from '../../title/title';

// apis
import { 
   updateCollectionReq, 
   deleteTagsFromCollection, 
   addTagsToCollection, 
   getCollectionById,
   getTags,
   getRecentlyCollectionsReq
} from '../../../service/requests';

// icons 
import deleteTag from '../../../img/ico/deleteTag.svg'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';

// actions 
import { upsertCollectionToClass } from '../../../actions/classes';
import { setTags } from '../../../actions/tags';
import { closePopup } from '../../../actions/popup';
import { updateCollection,setRecentlyUsedCollections } from '../../../actions/collections';
import { openNotification } from '../../../actions/notification';

const mapDispatchToProps = dispatch => ({
   closePopup: () => {
      return dispatch(closePopup());
   },
   updateCollection: (payload) => {
     return dispatch(updateCollection(payload));
   },
   setTags: (payload) => {
      return dispatch(setTags(payload));
   },
   upsertCollectionToClass: (payload) => {
      return dispatch(upsertCollectionToClass(payload));
   },
   openNotification: (payload) => {
      return dispatch(openNotification(payload));
   },
   setRecentlyUsedCollections: (payload) => {
      return dispatch(setRecentlyUsedCollections(payload));
   }
 });


const EditCollection = (props) => {
   const {
      info,
      updateCollection,
      closePopup,
      setTags,
      upsertCollectionToClass,
      openNotification,
      setRecentlyUsedCollections
   } = props;

   const [name, setName] = useState(info.collection.name);
   const [collectionsTags, setCollectionsTags] = useState(info.collection.tags);
   const filteredTags = info.allTags.filter((elem) => !collectionsTags.find(({ id }) => elem.id === id));
   const [selectedTag, setSelectedTag] = useState();
    
   const [addedTags, setAddedTags] = useState([])
   const [addedNewTags, setAddedNewTags] = useState([]);
   const [deletedTags, setDeletedTags] = useState([]);

   const [allTags, setAllTags] = useState([...collectionsTags, ...addedTags, ...addedNewTags]);

   const [error, setError] = useState(false);
   const [pressEnterHint, setPressEnterHint] = useState(false);
   const [values, setValues] = React.useState([]);
   const [isDsabledSaveBtn, setIsDisabledSaveBtn] = useState(false);

   useEffect(() => {
      setAllTags([...collectionsTags, ...addedTags, ...addedNewTags])
   }, [addedTags, addedNewTags, deletedTags]);


    const onTagDelete = (tag) => {
      setCollectionsTags(prevTags => prevTags.filter(x => x !== tag));
      setAddedTags(prevTags => prevTags.filter(x => x !== tag));
      setAddedNewTags(prevTags => prevTags.filter(x => x !== tag));

      if (tag.hasOwnProperty('_id')) {
        setDeletedTags(prevDeletedTags => [...prevDeletedTags, tag._id]);
      }
    }

   const handleKeyDown = (e) => {
      if (e.key === 'Enter' && e.target.value != "") {
         if (selectedTag && selectedTag.name == e.target.value) {
            setAddedTags([...addedTags, selectedTag])
            e.target.value = ''

         } else {
            setAddedNewTags([...addedNewTags, e.target.value])
            e.target.value = ''
            
         }
         setValues([]);
         setPressEnterHint(false)
      }
   }

   const getRecentCollections = async () => {
      try {
         const res = await getRecentlyCollectionsReq();
         if(res) {
            console.log('res.data.collections', res.data.collections)
            setRecentlyUsedCollections({collections: res.data.collections})
         }
      } catch(e) {
         console.log('error', e)
      }
   }

   const onUpdateCollection = async () => {
      setIsDisabledSaveBtn(true);
      
      if(name === info.collection.name && !addedTags.length && !deletedTags.length && !addedNewTags) {
         setError(true);
         return;
      }

      if(name != info.collection.name) {
         try {
            const res = await updateCollectionReq(info.collection._id, {name: name, classId: info.collection.classId});
            console.log('result', res);
            getRecentCollections();
         } catch(e) {
            console.log('error', e)
         }
      }
      
      if(addedNewTags.length) {
         await addTagsToCollection(info.collection._id, {createTags: addedNewTags});

         const resTags = await getTags();
         setTags({tags: resTags.data.tags});
      }

      if(addedTags.length) {
         await addTagsToCollection(info.collection._id, {tags: addedTags.map(x => x._id)});
     
      }
      
      if(deletedTags.length) {
         await deleteTagsFromCollection(info.collection._id, {tags: deletedTags});
      }
      
      const resCollection = await getCollectionById(info.collection._id);
      updateCollection({ collection: resCollection.data.collection });

      if(resCollection.data.collection.classId) {
         info.onUpdateCollection(resCollection.data.collection)
         // upsertCollectionToClass({collection :resCollection.data.collection   });
      }

      closePopup();
      openNotification({text: 'Your collection updated successfully', notificationType: 'success' });

   }


   const onChangeAutocomplete = (value) => {
      setSelectedTag(value);
      setPressEnterHint(true);
   }

   const onChangeTagsInput = (e) => {
      if (e.target.value != "") {
         setPressEnterHint(true)
      }
   }

   return (
      <section className="my-6 mx-5 md:mx-20 relative editCollection">
         <PopupTitle title="Edit collection"/>
         <div className="flex flex-col mt-3">
            <label className="text-textGray dark:text-darkModeGray font-light text-sm">Name</label>
            <input type="text" defaultValue={name} onChange={(e) => setName(e.target.value)} className="popupInput"></input>
         </div>

         <div className="flex flex-col mt-3">
            <div className="flex justify-between">
               <label className="text-textGray dark:text-darkModeGray font-light text-sm">Tags</label>
               {pressEnterHint && <div className="text-gray text-xs">Press Enter to confirm selected tag</div>}
            </div>

            <Autocomplete
               className='relative w-full'
               options={filteredTags}
               componentsProps={{ popper: { style: { width: 'fit-content' } } }}
               renderOption={(props, option, state) => (
                  <div className="text-dark dark:text-whitish" {...props}>
                     <div className="w-32 text-dark dark:text-whitish">{option.name}</div>
                  </div>
               )}
               getOptionLabel={(option) => option.name}
               onChange={(event, newValue) => {
                  onChangeAutocomplete(newValue)
               }}
               id="combo-box-demo"
               // value={values}
               size="small"
               popupIcon={<ArrowDropDownRoundedIcon className="text-purple" />}
               renderInput={(params) => {
                  return (
                     <TextField
                        {...params}
                        placeholder="Choose from list or add new tag(s)"
                        className="editCollection__inputAutocomplete"
                        onChange={(e) => onChangeTagsInput(e)}
                        onKeyDown={(e) => handleKeyDown(e)} variant="outlined"
                     />
                  );
               }}
            />
         </div>


         <div className="flex mt-2 mb-4 flex-wrap">
            { allTags.length > 0 && 
               allTags.map(tag => {
                  return (
                     <div className="flex items-center rounded-md bg-brightLightPurple dark:bg-darkModeTransparentPurple w-max px-2 py-0.5 my-1 mr-2 cursor-pointer transition border border-brightLightPurple dark:border-transparent hover:border-1 hover:border-purple">
                        <div className="text-purple text-sm">{tag.name ? tag.name : tag}</div>
                        <img src={deleteTag} className="ml-2" onClick={() => onTagDelete(tag)} />
                     </div>
                  )
               })
            }

         </div>
         { error && <div className="absolute bottom-3 right-0 text-center text-xs text-errorRed">no changes to update</div> }

         {isDsabledSaveBtn 
         ?
            <div className="mx-auto w-min rounded-lg overflow-hidden">
               <ButtonBase><div className="mx-auto bg-lightGray text-sm text-white py-2 px-7 w-max rounded-md flex items-center"><span>Save</span><span className="ml-3 h-3.5"><CircularProgress size={15} sx={{ color: '#fff'}}/></span></div></ButtonBase>
            </div>
         :
            <div className="mx-auto w-min rounded-lg overflow-hidden" onClick={onUpdateCollection}>
               <ButtonBase><div className="mx-auto bg-darkPurple text-sm text-white py-2 px-7 w-max rounded-md cursor-pointer hover:bg-hoverPurple transition">Save</div></ButtonBase>
            </div>
         }

      </section>
   )
}

export default connect(
   null,
   mapDispatchToProps
 )(EditCollection);
