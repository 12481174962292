import {
    SET_LANGUAGES,
 } from './index';
 
 
 export const setLanguages = (payload) => {
    const { languages } = payload;
    return {
        type: SET_LANGUAGES,
        languages
    }
 }