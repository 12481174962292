import axios from 'axios';
import store from '../store';
import { setIsNotFound } from '../actions/pages';

axios.interceptors.request.use(config => {
    const token = localStorage.getItem('accessToken');

    config.headers = {
        'content-type': 'application/json',
        'x-accesstoken': (token) ? token : ''
    }

    return config;
});

axios.interceptors.response.use(
    response => response, err => {
    console.log(err, 'errrr');

        if(err.response?.status === 401 && window.localStorage.getItem('token')) {
            window.localStorage.clear();
            window.location.reload();
            return err;
        }
        
        if(err.response.status === 404) {
            store.dispatch(setIsNotFound({isNotFound: true}));
        }

    return Promise.reject(err.response);
});



// export const baseURL = process.env.NODE_ENV === 'production' ? '/' : 'http://localhost:8082/';
// export const baseURL = process.env.NODE_ENV !== 'production' ? '/' : 'https://api.r-words.com/';
export const baseURL = 'https://api.r-words.com/';
export const appURL = 'https://r-words.com/';

const api = baseURL + 'api/v1/';
const usersAPI = api + 'users/';
const collectionsAPI = api + 'collections/';
const tagsAPI = api + 'tags/';
const wordsAPI = api + 'words/';
const classesAPI = api + 'classes/';
const invitationsAPI = api + 'invitations/';
const practiceAPI = api + 'practice/';

// USERS API
export const createUser = (payload) => axios.post(usersAPI + "register", payload);
export const loginUser = (payload) => axios.post(usersAPI + "login", payload);
export const getUser = () => axios.get(usersAPI + "me");
export const checkAuth = () => axios.get(usersAPI + "check");
export const checkUserExist = (payload) => axios.post(usersAPI + "exist", payload);
export const updateUser = (payload) => axios.put(usersAPI + "me", payload);
export const addUserPhoto = (payload) => {

    const token = localStorage.getItem('accessToken');
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
            'x-accesstoken': (token) ? token : ''
        }
    }
    let test = axios.create(config);
    return test.post(usersAPI + "me/upload-image", payload);
}
export const deleteUserPhoto = () => axios.delete(usersAPI + "me/avatar");
export const deleteAccount = () => axios.delete(usersAPI + "me");
export const checkPassword = (payload) => axios.post(usersAPI + "check/pass", payload);
export const changePassword = (payload) => axios.post(usersAPI + "newpass", payload);
export const createPassword = (payload) => axios.put(usersAPI + "pass", payload);
export const googleAuth = (payload) => axios.post(usersAPI + "auth/google", payload);
export const googleAuthContinue = (payload) => axios.post(usersAPI + "auth/continue", payload);


// COLLECTIONS API
export const getRecentlyCollectionsReq = () => axios.get(collectionsAPI + "recently");

export const getCollections = (page) => {
    const params = new URLSearchParams({
        page,
    });
    return axios.get(collectionsAPI + '?' + params.toString());
}

export const getCollectionsByClassId = (classId) => {
    const params = new URLSearchParams({
        classId,
    });
    return axios.get(collectionsAPI + '?' + params.toString() + '/collections');
}

export const findCollections = (name, page, limit) => {
    const params = new URLSearchParams({
        name,
        page,
        limit
    });

    removeNullsAndUndefined(params);
    return axios.get(collectionsAPI + '?' + params.toString());
}

export const findAllCollectionsFromClasses = (name, page, limit) => {
    const params = new URLSearchParams({
        name,
        page,
        limit
    });

    removeNullsAndUndefined(params);
    return axios.get(collectionsAPI + "classes" + '?' + params.toString());
}

export const getCollectionById = (id, page, word, limit) => { 
    const params = new URLSearchParams({
      page,
      word,
      limit
    });
      removeNullsAndUndefined(params);
    return axios.get(collectionsAPI + id + '?'+ params.toString());
}

export const addCollectionReq = (payload) => axios.post(collectionsAPI, payload);
export const deleteCollectionReq = (id) => axios.delete(collectionsAPI + id);
export const updateCollectionReq = (id, payload) => axios.put(collectionsAPI + id, payload);
export const changeCollectionSharedStatus = (id, payload) => axios.put(collectionsAPI + id + "/shared", payload);
export const getAllCollectionsFromClasses = () => 
{
return axios.get(collectionsAPI + "classes");
}

export const deleteTagsFromCollection = (id, payload) => axios.put(collectionsAPI + id + "/tags", payload);
export const addTagsToCollection = (id, payload) => axios.post(collectionsAPI + id + "/tags", payload);

export const addWordsToCollection = (id, payload) => axios.post(collectionsAPI + id + "/words", payload);
export const addWordToCollection = (collectionId, wordId) => axios.get(collectionsAPI + collectionId + wordId);
export const deleteWordsFromCollection = (id, words) => axios.delete(collectionsAPI + id + "/many", { data: { words } });
export const deleteWordFromCollection = (id, wordId) => axios.delete(collectionsAPI + id + "/" + wordId);

export const loadCollection = (id, payload) => axios.post(collectionsAPI + id + "/pdf", payload);



// TAGS API
export const getTags = () => axios.get(tagsAPI);
export const getTagById = (id) => axios.get(collectionsAPI + id);
export const addTagReq = (payload) => axios.post(tagsAPI, payload)
export const deleteTagReq = (id) => axios.delete(tagsAPI + id);
export const updateTagReq = (id) => axios.put(tagsAPI + id);

// WORDS API

export const findWords = (id, text, page, limit) => {
    const params = new URLSearchParams({
        text,
        page,
        limit
    });

    removeNullsAndUndefined(params);

    return axios.get(collectionsAPI + id + '?' + params.toString());
}
export const translateWord = (payload) => axios.post(wordsAPI + 'translate', payload);
export const saveWord = (payload) => axios.post(wordsAPI + 'save', payload);
export const updateWord = (id, payload) => axios.put(wordsAPI + id, payload);


// CLASSES API
export const addClassReq = (payload) => axios.post(classesAPI, payload);
export const getClasses = () => axios.get(classesAPI);


export const findClasses = (name, page, limit) => {
    const params = new URLSearchParams({
        name,
        page,
        limit
    });

    removeNullsAndUndefined(params);
    return axios.get(classesAPI + '?' + params.toString());
}


export const getCollectionsFromClass = (id, name, page, limit) => {
    const params = new URLSearchParams({
        name,
        page,
        limit
    });

    removeNullsAndUndefined(params);
    return axios.get(classesAPI + id + '/collections/' + '?' + params.toString());
}

export const getClassById = (id) => axios.get(classesAPI + id);
export const updateClassReq = (id, payload) => axios.put(classesAPI + id, payload);
export const deleteClassReq = (id) => axios.delete(classesAPI + id);
export const leaveClassReq = (id) => axios.delete(classesAPI + id + '/leave');
export const removeStudent = (classId, studentId) => axios.delete(classesAPI + classId + '/student/' + studentId);


// INVITATIONS API
export const getInvitationInfo = (id) => axios.get(invitationsAPI + id);
export const sendInvitation = (id, payload) => axios.post(invitationsAPI + id + "/student", payload);
export const acceptInvitation = (id) => axios.get(invitationsAPI + id + "/accept");

// PRACTICE API
export const getPracticeData = (id, types, amount) => {
    const params = new URLSearchParams({
        types,
        amount
    });
    return axios.get(practiceAPI + id + '/generate/' + '?' + params.toString());
}

export const getPracticeResults = () => axios.get(practiceAPI);
export const getPracticeResultById = (id) => axios.get(practiceAPI + id);
export const savePracticeResult = (collectionId, payload) => axios.post(practiceAPI + collectionId + "/save", payload);


// CONFIGS
export const getLanguages = () => axios.get(api + "configs/langs");

export const removeNullsAndUndefined = (params) => {
    let keysForDel = [];
    params.forEach((value, key) => {
        if (value == "null" || value == "undefined" ) {
            keysForDel.push(key);
        }
    });

    keysForDel.forEach(key => {
        params.delete(key);
    });
}